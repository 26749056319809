'use client';

import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs';
import { getCookie } from 'cookies-next';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

function InAppProvider() {
	const inApp = useAtomValue(inAppBrowserAtom);
	const searchParams = useSearchParams();
	const login = searchParams.get('login');

	if (!inApp) return null;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (login) {
			const accessData = {
				accessToken: getCookie(CookieNames.JWT),
				refreshToken: getCookie(CookieNames.REFRESH_TOKEN),
				expiresIn: 86400,
				tokenType: 'Bearer',
			};

			if (window?.vuplex) {
				window.vuplex.postMessage({
					type: 'auth',
					...accessData,
				});
			}
		}
	}, [login, searchParams]);

	return <meta content='true' name='transparent' />;
}

export default InAppProvider;
