/* eslint-disable no-nested-ternary */
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CloseIcon, LogoXIcon, MagicWandIcon, WarningIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames, urls } from '@mpx-sdk/shared/configs';
import { GradientTypography } from '@mpx-sdk/ui/components/core';
import IconText from '@mpx-sdk/ui/components/core/IconText';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { getCookie } from 'cookies-next';
import { useAtomValue } from 'jotai';
import { debounce } from 'lodash';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { type ReactElement, useEffect, useState } from 'react';

interface UpdateMessage {
	title?: string | ReactElement;
	body: string | ReactElement;
}

export default function UpdatesDialog(): ReactElement | null {
	const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
	const [hasSeenUpdate, setHasSeenUpdate] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);
	const inApp = useAtomValue(inAppBrowserAtom);
	const pathname = usePathname();
	const responsiveView = UIHelper.isResponsiveView();

	const textStyle = {
		fontSize: 'clamp(0.7rem, 1rem, 1.5rem)',
	};

	// Update this date to show this update dialog again
	const updateStamp = '2023-11-21';
	const expireUpdate = '2023-12-30';
	const itemName = `hasSeenUpdate-${updateStamp}`;

	const buttonStyle = {
		background: (theme) => theme.palette.gradient.main,
		borderRadius: '100px',
		fontSize: 'clamp(0.5rem, 1rem, 1rem)',
		fontWeight: '700',
		letterSpacing: '0.0025em',
		lineHeight: '32px',
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
		textTransform: 'none',
		whiteSpace: 'nowrap',
		'&:hover': {
			background: (theme) => theme.palette.gradient.hover,
		},
	};

	const welcomeMessages: UpdateMessage[] = [
		{
			title: (
				<Typography
					fontWeight='bold'
					mb={2}
					sx={{
						fontSize: '1.8rem',
					}}
					textAlign='center'
					variant='h4'
				>
					<GradientTypography component='span' variant='inherit'>
						Generate 3D Models
					</GradientTypography>
					<br />
					with AI in VR!
				</Typography>
			),
			body: (
				<Typography sx={textStyle}>
					Use our{' '}
					<Box
						sx={{
							color: 'primary.main',
							display: 'inline',
							fontWeight: 800,
						}}
					>
						Text-to-3D
					</Box>{' '}
					AI tools to create 3D characters, animals and objects{' '}
					<Box
						sx={{
							color: 'primary.main',
							display: 'inline',
							fontWeight: 800,
						}}
					>
						{inApp ? 'in minutes' : 'also in VR'}
					</Box>
					.
					<br />
					<br />
					{inApp ? (
						<>
							Then,{' '}
							<Box
								sx={{
									color: 'primary.main',
									display: 'inline',
									fontWeight: 800,
								}}
							>
								remix
							</Box>{' '}
							them here right away!
						</>
					) : (
						<>
							Download the{' '}
							<Box
								sx={{
									color: 'primary.main',
									display: 'inline',
									fontWeight: 800,
								}}
							>
								Masterpiece X
							</Box>{' '}
							app from the Meta Quest store to try it out and{' '}
							<Box
								sx={{
									color: 'primary.main',
									display: 'inline',
									fontWeight: 800,
								}}
							>
								remix
							</Box>{' '}
							your generations!
						</>
					)}
				</Typography>
			),
		},
		{
			body: (
				<Box>
					<Typography
						sx={{
							textAlign: 'left',
						}}
						variant='h6'
					>
						Capabilities &amp; Limitations
					</Typography>
					<Stack
						spacing={2}
						style={{
							display: 'flex',
							padding: '2px',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Stack
							sx={{
								backgroundColor: 'background.offBlack',
								borderRadius: '6px',
								height: 'auto',
								padding: '15px',
								width: '100%',
							}}
						>
							<IconText color='white' padding='1px' variant='h6'>
								<MagicWandIcon
									fontSize='small'
									sx={{
										margin: '1%',
										color: 'primary.main',
									}}
								/>
								&nbsp; &nbsp; Capabilities:
							</IconText>

							<Typography
								sx={{
									textAlign: 'left',
									fontSize: 'clamp(0.7rem, 1rem, 1rem)',
								}}
								variant='body1'
							>
								<ul>
									<li>Create humans, animals, and object.</li>
									<li>Add animations to human models only.</li>
									<li>
										Edit the generated models in our{' '}
										{inApp ? (
											'VR app'
										) : (
											<Link
												href={urls.app.oculus}
												rel='noopener noreferrer'
												style={{
													color: '#7FBEF6',
												}}
												target='_blank'
											>
												VR app
											</Link>
										)}
										.
									</li>
									<li>Export to .GLB format.</li>
								</ul>
							</Typography>
						</Stack>

						<Stack
							sx={{
								backgroundColor: 'background.offBlack',
								borderRadius: '6px',
								height: 'auto',
								mx: '5%',
								padding: '15px',
								width: '100%',
							}}
						>
							<IconText color='white' padding='1px' variant='h6'>
								<WarningIcon
									fontSize='small'
									sx={{
										margin: '1%',
									}}
								/>
								&nbsp; &nbsp; Limitations:
							</IconText>

							<Typography
								sx={{
									textAlign: 'left',
									fontSize: 'clamp(0.7rem, 1rem, 1rem)',
								}}
								variant='body1'
							>
								<ul>
									<li>
										It&apos;s NOT for creating uncommon or obscure objects such as a “Gorilla
										without legs”.
									</li>
									<li>
										It may take{' '}
										<Box
											sx={{
												color: 'primary.main',
												display: 'inline',
												fontWeight: 800,
											}}
										>
											multiple generations
										</Box>{' '}
										to get your desired result.
									</li>
								</ul>
							</Typography>
						</Stack>
					</Stack>
				</Box>
			),
		},
	];

	const handleContinueExploring = () => {
		if (currentMessageIndex < welcomeMessages.length - 1) {
			setCurrentMessageIndex((prevIndex) => prevIndex + 1);
		} else {
			setIsOpen(false);
			if (localStorage) {
				localStorage?.setItem(itemName, 'true');
				setHasSeenUpdate(true);
			}
		}
	};

	const checkHasSeenUpdate = debounce(() => {
		/** Check if the disable cookie is not enabled/set */
		const disableCookie = getCookie(CookieNames.DISABLE_WELCOME);

		const hasSeenUpdate = localStorage?.getItem(itemName);

		if (hasSeenUpdate || new Date() > new Date(expireUpdate) || disableCookie) {
			setHasSeenUpdate(true);
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}

		if (localStorage) {
			localStorage?.setItem(itemName, 'true');
		}
	}, 1000);

	useEffect(() => {
		if (!pathname?.startsWith('/login')) {
			checkHasSeenUpdate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (hasSeenUpdate) {
		return null; // Render nothing if the user has already seen the welcome dialog or if the current route is not index
	}

	const currentMessage = welcomeMessages[currentMessageIndex];

	return (
		<Dialog
			aria-modal='true'
			data-testid='welcome-dialog'
			onClose={() => {
				setIsOpen(false);
			}}
			open={isOpen}
			PaperProps={{
				sx: {
					backgroundColor: '#1F1F1F',
					backgroundImage: 'none',
					maxWidth: 'min(90vw, 550px)',
					borderRadius: {
						xs: 0,
						lg: 1,
					},
				},
			}}
			sx={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
			}}
		>
			{currentMessage.title && (
				<DialogTitle
					sx={{
						fontWeight: 'bold',
					}}
					variant='h4'
				>
					{currentMessage.title}
				</DialogTitle>
			)}

			<DialogContent>
				{currentMessage.body}

				<Stack
					alignItems='center'
					direction={inApp || responsiveView ? 'row' : 'column'}
					justifyContent='center'
					spacing={3}
					sx={{
						margin: '2.5rem auto 1rem',
					}}
				>
					{inApp ? (
						currentMessageIndex === welcomeMessages.length - 1 ? (
							<Button onClick={handleContinueExploring} sx={buttonStyle} variant='contained'>
								Got It!
							</Button>
						) : (
							<Button onClick={handleContinueExploring} sx={buttonStyle} variant='contained'>
								Next
							</Button>
						)
					) : null}

					{/* Custom button for this update */}
					{!inApp && (
						<Link href={urls.app.oculus} rel='noopener noreferrer' target='_blank'>
							<Button
								onClick={() => {
									if (inApp) {
										handleContinueExploring();
									} else {
										setIsOpen(false);
									}
								}}
								sx={buttonStyle}
								variant='contained'
							>
								<LogoXIcon
									sx={{
										marginRight: '0.25rem',
									}}
								/>{' '}
								Meta Quest App
							</Button>
						</Link>
					)}

					<Link href='/generate' shallow>
						<Button
							onClick={() => {
								if (inApp) {
									handleContinueExploring();
								} else {
									setIsOpen(false);
								}
							}}
							sx={buttonStyle}
							variant='contained'
						>
							<MagicWandIcon
								sx={{
									marginRight: '0.25rem',
								}}
							/>{' '}
							Generate 3D Models Now
						</Button>
					</Link>
				</Stack>

				<IconButton
					aria-label='Close'
					onClick={() => {
						setIsOpen(false);
					}}
					sx={{
						backgroundColor: 'background.offBlack',
						position: 'absolute',
						right: 10,
						top: 10,
						'&:hover': {
							color: 'primary.main',
							svg: {
								fill: 'primary.main',
							},
						},
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogContent>
		</Dialog>
	);
}
