'use client';

import {
	adminFeaturesAtom,
	availableCreditsAtom,
	fastCommentsSSOAtom,
	inAppBrowserAtom,
	userAtom,
	userRolesAtom,
	userSlotsAtom,
} from '@mpx-sdk/shared/atoms';
import { useHydrateAtoms } from 'jotai/utils';

/**
 * This component hydrates the atoms with the initial state. Meaning it's also going to add these values on server-side and send it to client.
 */
function HydrateAtoms({ children, initialState }) {
	const atomsMap = {
		user: userAtom,
		userRoles: userRolesAtom,
		adminFeatures: adminFeaturesAtom,
		fastCommentsSSO: fastCommentsSSOAtom,
		isInAppBrowser: inAppBrowserAtom,
		availableCredits: availableCreditsAtom,
		userSlots: userSlotsAtom,
	};

	const atomsToHydrate: any = [];

	Object.keys(atomsMap).forEach((key) => {
		const atom = atomsMap[key];
		const value = initialState[key];
		atomsToHydrate.push([atom, value]);
	});

	useHydrateAtoms(atomsToHydrate);

	return children;
}

export default HydrateAtoms;
