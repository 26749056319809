import Auth0Helper from '@mpx-sdk/helpers/auth';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { inAppBrowserAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { type ReactElement, useEffect, useState } from 'react';

/** A dialog that is displayed when authentication fails due to an expired or invalid JWT token. */
export default function AuthFailedDialog(): ReactElement | null {
	const router = useRouter();
	const pathname = usePathname();
	const [isOpen, setIsOpen] = useState(false);
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);

	const handleLogout = async () => {
		setIsOpen(false);

		if (window?.vuplex) {
			window.vuplex.postMessage({
				type: 'auth-logout',
				logout: true,
			});
		}

		// Track the event
		DataLayer.triggerMeasurementEvent('authEvent', {
			event_name: 'auth_failed',
		});

		Auth0Helper.logout({
			returnTo: window.location.origin,
		});
	};

	useEffect(() => {
		// Check if the jwt cookie exists and there is no current user, show the auth failed dialog
		const getCookie = (name) =>
			document.cookie
				.split('; ')
				.find((cookie) => cookie.startsWith(`${name}=`))
				?.split('=')[1] ?? null;
		const jwtCookie = getCookie('jwt');

		// Check if the page is '/login/device'
		const isInAppLoginPage = pathname === urls.routes.deviceLogin;

		if (!currentUser && jwtCookie && !isInAppLoginPage) {
			// setIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			aria-modal='true'
			data-testid='auth-failed-dialog'
			onClose={() => {
				setIsOpen(false);
			}}
			open={isOpen}
			PaperProps={{
				sx: {
					backgroundColor: 'original.main',
					color: 'original.contrastText',
				},
			}}
			sx={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
			}}
		>
			<DialogTitle
				sx={{
					fontSize: '1.7rem',
				}}
			>
				Authentication Failed
				<Divider
					flexItem
					sx={{
						backgroundColor: 'original.contrastText',
						height: '4px',
					}}
				/>
			</DialogTitle>
			<DialogContent>
				<Typography
					sx={{
						fontSize: 'clamp(0.7rem, 1rem, 1.5rem)',
					}}
				>
					There was a problem with your authentication. Please try again.
				</Typography>

				<br />
				<Stack
					alignItems='center'
					direction='column'
					justifyContent='center'
					spacing={3}
					sx={{
						marginTop: '1rem',
						width: '100%',
						a: {
							width: '100%',
						},
						button: {
							width: '100%',
						},
					}}
				>
					{/* Buttons */}
					<Button
						aria-label='Try again: Refresh page or login'
						color='secondary'
						fullWidth
						onClick={() => {
							if (inApp) {
								router.refresh();
							} else {
								Auth0Helper.login();
							}

							setIsOpen(false);
						}}
						variant='contained'
					>
						Try Again
					</Button>

					<Stack
						direction='row'
						spacing={2}
						sx={{
							width: '100%',
						}}
					>
						<Link aria-label='Get help and feedback' href='/feedback'>
							<Button
								aria-label='Get help'
								color='secondary'
								onClick={() => {
									setIsOpen(false);
								}}
								variant='contained'
							>
								Help
							</Button>
						</Link>

						<Button aria-label='Logout' color='secondary' onClick={handleLogout} variant='contained'>
							Logout
						</Button>

						<Button
							aria-label='Close'
							color='secondary'
							onClick={() => {
								setIsOpen(false);
							}}
							variant='contained'
						>
							Close
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
