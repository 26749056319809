'use client';

import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import AuthFailedDialog from '@mpx-sdk/ui/components/dialogs/AuthFailedDialog';
import ErrorDialog from '@mpx-sdk/ui/components/dialogs/ErrorDialog';
import TutorialHandoffDialog from '@mpx-sdk/ui/components/dialogs/TutorialHandoffDialog';
import UpdatesDialog from '@mpx-sdk/ui/components/dialogs/UpdatesDialog';
import FlagDialog from '@mpx-sdk/ui/components/flag/FlagDialog';
import MaxSlots from '@mpx-sdk/ui/components/slots/MaxSlotsDialog';
import SocialSharingModel from '@mpx-sdk/ui/components/social-sharing/SocialSharingModel';
import ExportDialog from '@mpx-sdk/ui/components/vr/export/ExportDialog';
import { useAtomValue } from 'jotai';

/** The global dialogs that appear on every page. */
export default function GlobalDialogs() {
	const inApp = useAtomValue(inAppBrowserAtom);

	return (
		<>
			{/* Opens a dialog telling the users of new updates */}
			<UpdatesDialog />

			{/* Max slots dialog relating to user project slots */}
			<MaxSlots />

			{/* Export flow dialog */}
			{inApp && <ExportDialog />}

			{/* Flagging project */}
			<FlagDialog />

			{/* Social sharing dialog */}
			<SocialSharingModel />

			{/* Error dialog */}
			<ErrorDialog />

			{/* Auth failed dialog */}
			<AuthFailedDialog />

			{/* Tutorial hand-off dialogs */}
			<TutorialHandoffDialog />
		</>
	);
}
