import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/brinx/web-development/fullstack-monorepo/apps/mpx-frontend/src/components/vr/InAppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/brinx/web-development/fullstack-monorepo/apps/mpx-frontend/src/layouts/DashboardLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/brinx/web-development/fullstack-monorepo/libs/ui/src/components/analytics/AnalyticsWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/brinx/web-development/fullstack-monorepo/libs/ui/src/components/HydrateAtoms.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider","JotaiProvider","ThemeRegistry","DialogServiceProvider"] */ "/builds/brinx/web-development/fullstack-monorepo/libs/ui/src/components/index.ts");
;
import(/* webpackMode: "eager" */ "/builds/brinx/web-development/fullstack-monorepo/libs/ui/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/builds/brinx/web-development/fullstack-monorepo/node_modules/jotai/esm/react.mjs");
