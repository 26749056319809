import { Alert, NoSsr, Slide } from '@mui/material';
import { useState } from 'react';

type Props = {
	message: string | React.ReactNode;
	onClose?: () => void;
	severity: 'success' | 'info' | 'warning' | 'error';
};

function FlashMessage(props: Props) {
	const { message, severity, onClose } = props;
	const [open, setOpen] = useState(true);

	return (
		<NoSsr>
			<Slide direction='down' in={open} mountOnEnter unmountOnExit>
				<Alert
					icon={false}
					onClose={() => {
						setOpen(false);
						onClose?.();
					}}
					severity={severity}
					sx={{
						bgcolor: 'primary.main',
						background: (theme: any) => (severity === 'warning' ? theme.palette.gradient.private : null),
						borderRadius: 0,
						textAlign: 'center',
						'& .MuiAlert-message': {
							margin: 'auto',
						},
						'& .MuiAlert-action': {
							marginLeft: 0,
						},
					}}
					variant='filled'
				>
					{message}
				</Alert>
			</Slide>
		</NoSsr>
	);
}

export default FlashMessage;
