/* eslint-disable no-nested-ternary */
import { DiscordIcon } from '@mpx-sdk/images';
import { userAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import { urls } from '@mpx-sdk/shared/configs/urls';
import {
	Facebook as FacebookIcon,
	Instagram as InstagramIcon,
	LinkedIn as LinkedInIcon,
	Twitter as TwitterIcon,
	YouTube as YouTubeIcon,
} from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { type ReactElement, useMemo } from 'react';

/** Page footer */
export default function Footer(): ReactElement | null {
	const currentUser = useAtomValue(userAtom);
	const pathname = usePathname();

	const socials = useMemo(
		() => [
			{
				name: 'Discord',
				icon: <DiscordIcon />,
				url: 'https://discord.gg/Ca5ZDV3uxD',
				newTab: true,
			},
			{
				name: 'YouTube',
				icon: <YouTubeIcon />,
				url: 'https://www.youtube.com/@withMPStudio',
				newTab: true,
			},
			{
				name: 'Twitter',
				icon: <TwitterIcon />,
				url: 'https://twitter.com/withMPStudio',
				newTab: true,
			},
			{
				name: 'Facebook',
				icon: <FacebookIcon />,
				url: 'https://www.facebook.com/withMPStudio/',
				newTab: true,
			},
			{
				name: 'Instagram',
				icon: <InstagramIcon />,
				url: 'https://www.instagram.com/withmpstudio/',
				newTab: true,
			},
			{
				name: 'LinkedIn',
				icon: <LinkedInIcon />,
				url: 'https://www.linkedin.com/company/masterpiecestudio/',
				newTab: true,
			},
		],
		[],
	);

	const footerOptions = useMemo(
		() => ({
			Company: [
				{
					name: 'Our Story',
					url: 'https://www.masterpiecex.com/our-story',
				},
				{
					name: 'Careers',
					url: 'https://www.masterpiecex.com/careers',
				},
				{
					name: 'Blog',
					url: 'https://www.masterpiecex.com/blog',
				},
			],
			Product: [
				{
					name: 'Login',
					// url: Auth0Helper.getLoginUrl() ?? urls.routes.login,
					hide: currentUser,
				},
				{
					name: 'Masterpiece X',
					url:
						typeof window !== 'undefined' && navigator?.userAgent?.match(/Oculus/)
							? urls.app.oculus
							: 'https://www.masterpiecex.com/home',
				},
				{
					name: 'Masterpiece X - Generate',
					url: Env.MPX_GENAI_WEBSITE,
				},
				{
					name: 'Studio Pro',
					url: 'https://www.masterpiecex.com/masterpiece-studio-pro',
					newTab: true,
				},
				{
					name: 'Studio Pro Login',
					url: urls.app.mps,
					newTab: true,
				},
			],
			Community: [
				{
					name: 'Library',
					url: urls.routes.library,
				},
				{
					name: 'Learn',
					url: urls.routes.learn,
				},
				{
					name: 'Support',
					url: `${Env.ZENDESK.url}/hc/en-us`,
					newTab: true,
				},
			],
		}),
		[currentUser],
	);

	const legal = 'https://www.masterpiecex.com/legal';
	// const privacyPolicy = 'https://www.masterpiecex.com/privacy-policy';
	// const termsOfUse = 'https://www.masterpiecex.com/masterpiecex-terms-of-use';

	if (pathname?.startsWith('/generate')) {
		return null;
	}

	return (
		<Grid
			alignItems='center'
			component='footer'
			container
			direction='row'
			justifyContent='flex-start'
			spacing={2}
			sx={{
				backgroundColor: (theme) => theme.palette.background.default,
				borderTop: (theme) => `1px solid ${theme.palette.borders.dark}`,
				display: 'flex',
				flexDirection: 'row',
				marginTop: '1.5rem',
				minWidth: '100%',
				padding: '3rem',
				paddingBottom: '5%',
				a: {
					color: 'text.primary',
				},
			}}
		>
			<Grid container item spacing={2} xs={12}>
				<Grid item md={12 - 2 * Object.keys(footerOptions).length} xs={12}>
					<Stack direction='column'>
						<Link aria-label='Masterpiece X' href={Env.MPX_WEBSITE}>
							<Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={2}>
								<IconButton
									aria-label='Masterpiece X logo button'
									className='navbar-logo-container'
									sx={{
										position: 'relative',
									}}
								>
									<Image
										alt='Masterpiece X'
										className='navbar-logo'
										height={45}
										loading='lazy'
										src='/logos/brand_x.svg'
										width={45}
									/>
								</IconButton>

								<Typography
									sx={{
										fontFamily: 'Circe',
										fontSize: '1.5rem',
										textTransform: 'uppercase',
									}}
								>
									Masterpiece X
								</Typography>
							</Stack>
						</Link>

						<Stack
							alignItems='center'
							direction='row'
							justifyContent='flex-start'
							sx={{
								marginTop: '1rem',
							}}
						>
							{/* Socials */}
							{socials.map((social) => (
								<Link
									key={`footer-social-${social.name}`}
									aria-label={`Social link to ${social.name}`}
									href={social.url}
									rel='noreferrer noopener'
									target='_blank'
								>
									<IconButton
										aria-label={`Social link button to ${social.name}`}
										sx={{
											color: '#fff',
										}}
									>
										{social.icon}
									</IconButton>
								</Link>
							))}
						</Stack>
					</Stack>
				</Grid>

				{Object.entries(footerOptions).map(([category, options]) => (
					<Grid key={category} item md={2} xs={6}>
						<Stack alignItems='flex-start' direction='column' justifyContent='flex-start' spacing={2}>
							<Typography className='footer-title'>{category}</Typography>

							{options.map((option) => {
								if (option.hide || !option.url) {
									return null;
								}

								return (
									<Link
										key={`footer-${category}-${option.name}`}
										href={option.url}
										rel='noreferrer noopener'
										target='_blank'
									>
										<Typography className='footer-action'>{option.name}</Typography>
									</Link>
								);
							})}
						</Stack>
					</Grid>
				))}
			</Grid>

			<hr
				style={{
					minWidth: '100%',
					margin: '4rem 0 2rem',
				}}
			/>

			<Grid container item spacing={2} xs={12}>
				{/* Copyright this year "Masterpiece X" */}
				<Grid item md={6} xs={12}>
					<Typography>&copy; {new Date().getFullYear()} Masterpiece Studio</Typography>
				</Grid>

				{/* Privacy Policy */}
				<Grid item md={2} xs={6}>
					{/* <LinkWrapper href={privacyPolicy} openNewTab>
						<Typography className='footer-action'>Privacy Policy</Typography>
					</LinkWrapper> */}
				</Grid>

				{/* Terms of Use */}
				<Grid item md={2} xs={6}>
					{/* <LinkWrapper href={termsOfUse} openNewTab>
						<Typography className='footer-action'>Terms of Use</Typography>
					</LinkWrapper> */}
				</Grid>

				{/* Legal */}
				<Grid item md={2} xs={6}>
					<Link href={legal} rel='noreferrer noopener' target='_blank'>
						<Typography className='footer-action'>Legal</Typography>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	);
}
