import Auth0Helper from '@mpx-sdk/helpers/auth';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { type ReactElement, useEffect, useState } from 'react';

export default function ErrorDialog(): ReactElement | null {
	const searchParams = useSearchParams();

	const [error, setError] = useState<string | string[] | null>(null);
	const [errorDescription, setErrorDescription] = useState<string | string[] | null>(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const error = searchParams.get('error');
		const errorDescription = searchParams.get('error_description');

		if (error || errorDescription) {
			setError(error ?? 'Error');
			setErrorDescription(errorDescription ?? 'Error');
			setOpen(true);
		}
	}, [searchParams]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			data-testid='error-dialog'
			onClose={handleClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: 'original.main',
					color: 'original.contrastText',
				},
			}}
			sx={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
			}}
		>
			<DialogTitle data-testid='error-dialog-title'>{error ?? 'Error'}</DialogTitle>
			<DialogContent>
				<DialogContentText
					data-testid='error-dialog-description'
					sx={{
						fontSize: 'clamp(0.7rem, 1rem, 1.5rem)',
						color: 'text.contrast',
					}}
				>
					{errorDescription ?? 'Error'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='center'
					spacing={2}
					sx={{
						width: '100%',
						margin: 'auto',
					}}
				>
					<Link aria-label='Get help and feedback' href='/feedback'>
						<Button
							aria-label='Get help/feedback'
							color='secondary'
							onClick={() => {
								setOpen(false);
							}}
							variant='contained'
						>
							Help
						</Button>
					</Link>

					<Button
						aria-label='Logout'
						color='secondary'
						onClick={async () => {
							if (window?.vuplex) {
								window.vuplex.postMessage({
									type: 'auth-logout',
									logout: true,
								});
							}

							// Track the event
							DataLayer.triggerMeasurementEvent('authEvent', {
								event_name: 'auth_failed',
							});

							Auth0Helper.logout({
								returnTo: window.location.origin,
							});
						}}
						variant='contained'
					>
						Logout
					</Button>

					<Button
						aria-label='close'
						color='secondary'
						data-testid='error-dialog-close-button'
						onClick={handleClose}
						variant='contained'
					>
						Close
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
}
