'use client';

import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { openShareDialogAtom } from '@mpx-sdk/shared/atoms';
import { TextField } from '@mpx-sdk/ui/components/core/TextField';
import SocialSharingIcons from '@mpx-sdk/ui/components/social-sharing/SocialSharingIcons';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { type ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type SocialShareOptions = 'facebook' | 'twitter' | 'linkedin' | 'pinterest' | 'reddit' | 'email' | 'navigator';

interface SocialSharingModelProps {
	/** The social media share buttons to display */
	socialShare?: SocialShareOptions[];
	projectId?: number;
}

/** Avoid loading this component on server, it uses document object which is not available on server */
export default function SocialSharingModel({
	projectId,
	socialShare = ['facebook', 'twitter', 'linkedin', 'pinterest', 'reddit', 'email', 'navigator'],
}: Readonly<SocialSharingModelProps>): ReactElement | null {
	const [isOpen, setIsOpen] = useAtom(openShareDialogAtom);
	const [shareLink, setShareLink] = useState('');
	const [shareText, setShareText] = useState('');
	const [shareTitle, setShareTitle] = useState('');

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const handleDeviceShare = async () => {
		if (navigator?.share && typeof navigator.share === 'function') {
			await navigator
				.share({
					text: shareText ?? 'Masterpiece X',
					title: shareText ?? 'Masterpiece X',
					url: shareLink ?? document?.location?.href ?? 'https://app.masterpiecex.com/',
				})
				.catch(() => {
					// Send analytics event
					DataLayer.triggerMeasurementEvent('share', {
						event_name: 'share_cancel',
						share_link: shareLink,
						share_method: 'navigator',
						share_navigator: true,
						share_text: shareText,
						share_title: shareTitle,
					});
				});
		}

		setIsOpen(false);
	};

	useEffect(() => {
		if (typeof window !== 'undefined' && document) {
			setShareLink(document?.location?.href ?? '');

			setShareTitle(document?.title ?? '');

			setShareText(document?.title ?? '');

			// Send analytics event
			if (
				isOpen &&
				typeof navigator !== 'undefined' &&
				typeof window !== 'undefined' &&
				navigator?.share &&
				typeof navigator.share === 'function' &&
				(navigator?.userAgent?.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Oculus/i) ||
					window.matchMedia('(display-mode: standalone)').matches)
			) {
				handleDeviceShare();

				DataLayer.triggerMeasurementEvent('share', {
					event_name: 'share_open',
					share_link: shareLink,
					share_method: 'navigator',
					share_navigator: true,
					share_text: shareText,
					share_title: shareTitle,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	// If navigator share and mobile device
	if (
		isOpen &&
		typeof navigator !== 'undefined' &&
		typeof window !== 'undefined' &&
		(navigator?.userAgent?.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Oculus/i) ||
			window.matchMedia('(display-mode: standalone)').matches)
	) {
		return null;
	}

	return (
		<Dialog
			aria-describedby='social-sharing-dialog-description'
			aria-label='social-sharing-dialog'
			aria-labelledby='social-sharing-dialog-title'
			onClose={handleCloseModal}
			open={isOpen ?? false}
		>
			<DialogTitle id='social-sharing-dialog-title'>
				<Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
					Share
					<IconButton aria-label='close' className='social-sharing-close-icon' onClick={handleCloseModal}>
						<CloseIcon
							sx={{
								color: 'primary.main',
							}}
						/>
					</IconButton>
				</Stack>
			</DialogTitle>

			<DialogContent>
				{/* Share buttons */}
				<Grid
					alignItems='center'
					className='social-sharing-icons-list'
					container
					direction='row'
					justifyContent='space-around'
				>
					{
						// Share buttons' JSX
						socialShare.map((social: string) => (
							<Grid key={`social-sharing-grid-${social}-${shareTitle}`} item>
								<SocialSharingIcons
									key={`social-sharing-icon-${social}-${shareTitle}`}
									projectId={projectId}
									shareLink={shareLink}
									social={social}
								/>
							</Grid>
						))
					}
				</Grid>
				{/* Copy share URL */}
				<Stack
					alignItems='stretch'
					className='social-sharing-copy-link'
					direction='row'
					justifyContent='center'
					spacing={1}
				>
					{/* Input with URL - read only */}
					<TextField
						aria-label='social-sharing-link'
						className='social-sharing-link'
						readOnly
						sx={{
							marginTop: 'auto !important',
							input: {
								backgroundColor: '#fff !important',
								color: '#000 !important',
							},
						}}
						type='text'
						value={shareLink}
					/>

					{/* Copy URL button */}
					<Button
						aria-label='Copy URL'
						onClick={() => {
							// Copy to clipboard
							navigator.clipboard.writeText(shareLink);

							toast.success('Link copied to clipboard');

							// Highlight the text
							const shareLinkText = document.querySelector('.social-sharing-link') as HTMLInputElement;
							if (shareLinkText?.getElementsByTagName('input')[0]) {
								shareLinkText.getElementsByTagName('input')[0].select();
							}

							// Send analytics event
							DataLayer.triggerMeasurementEvent('share', {
								event_name: 'share_open',
								share_link: shareLink,
								share_title: shareTitle,
								share_navigator: Boolean(
									navigator?.userAgent?.match(
										/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Oculus/i,
									) ||
										(typeof window !== 'undefined' &&
											window.matchMedia('(display-mode: standalone)').matches),
								),
								share_method: 'copy url',
							});
						}}
						variant='contained'
					>
						Copy URL
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
